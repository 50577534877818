<template>
    <!-- form modal -->

    <div
        v-if="showUpdateInventoryModal"
        tabindex="0"
        @keydown.esc="closeInventoryModal"
        class="w-100 center modal pt0-l pt7"
        id="add-product-form"
        :class="showUpdateInventoryModal && 'modal-target'"
        style="z-index: 999999"
        @click="handleOnClickOutsideModal"
    >
        <div class="w-50-l w-90 pv2 center box-border" style="background: white" ref="modalRef">
            <div class="flex justify-between pa3 box-border-bottom">
                <div class="f3 b">Update Inventory</div>
                <div>
                    <router-link to="#">
                        <img :src="require('@/assets/images/Close-icon.svg')" alt="" @click.prevent="closeInventoryModal" />
                    </router-link>
                </div>
            </div>
            <form class="entity-form" @submit.prevent="onSubmit">
                <div class="alert alert-info text-center">
                    You are are about to update <strong>{{ selectedProduct.name }}</strong> inventory. <br /> 
                    This will impact the product inventory negatively or positively depending on your <em class="b">New Stock</em> input.
                </div>
                <div class="pa3">
                    <div class="flex flex-wrap justify-between">
                        <div class="pt2 w-100 w-30-l pr2-l">
                            <div class="pv2 b">
                                <label for="current-stock">Current Stock</label>
                            </div>
                            <CurrencyInput
                                id="current-stock"
                                class="product-input w-100"
                                v-model="state.currentStockQty"
                                :options="numberFormat"
                                placeholder="0"
                                disabled
                            />
                        </div>

                        <div class="pt2 w-100 w-30-l pl2-l">
                            <div class="pv2 b">
                                <label for="new-stock">New Stock</label>
                            </div>
                            <CurrencyInput
                                id="new-stock"
                                class="product-input w-100"
                                v-model="state.newStockQty"
                                :options="numberFormat"
                                placeholder="0"
                            />
                        </div>

                        <div class="pt2 w-100 w-30-l pl2-l">
                            <div class="pv2 b tc" :class="{ 'text-danger': (state.newStockQty > 0 && state.variance < 0) }">
                                <label for="name">Variance</label>
                            </div>
                            <div v-if="state.newStockQty >= 0" class="tc" :class="{ 'text-danger': (state.newStockQty > 0 && state.variance < 0) }">
                                {{ state.variance }}
                            </div> 
                            <div v-else class="tc"> - </div>                           
                        </div>

                        <div v-if="state.variance !== 0" class="pt2 w-100 pl2-l" :class="{'w-30-l':  state.type === 'sales' || state.type === 'purchases'}">
                            <div class="pv2 b"><label for="type">Reason for changes in stock</label></div>
                            <select v-if="state.variance >= 0" id="type" v-model="state.type" class="w-100">
                                <option value="stock_introduced">Stock Update</option>
                                <option value="purchases">Purchase</option>
                            </select>
                            <select v-else id="type" v-model="state.type" class="w-100">
                                <option value="missing_stock">Missing Stock</option>
                                <option value="sales">Sales</option>
                                <option value="damaged_stock">Damaged Stock</option>
                                <option value="stolen_stock">Stolen Stock</option>
                                <option value="promotion_stock">Stock Used  in Promotion</option>
                            </select>
                        </div>

                        <div v-if="state.newStockQty >= 0 && (state.type === 'sales' || state.type === 'purchases')" class="pt2 w-100 w-30-l pl2-l">
                            <div class="pv2 font-w1"><label for="type">Account Type</label></div>
                            <select v-model="state.account" class="w-100" required>
                                <option value="">Select account </option>
                                <option v-for="(account, index) of state.account" :value="account?.type" :key="index">
                                    {{ account?.type }}
                                </option>
                            </select>
                        </div>

                        <div v-if="state.newStockQty >= 0 && (state.type === 'sales' || state.type === 'purchases')" class="pt2 w-100 w-30-l pl2-l">
                            <div class="pv2 b tc" :class="{ 'text-danger': (state.newStockQty > 0 && state.variance < 0) }">
                                <label for="name">Amount</label>
                            </div>
                            <div class="tc" :class="{ 'text-danger': (state.newStockQty > 0 && state.variance < 0) }">
                                {{ formatAmount(state.amount) }}
                            </div> 
                        </div>

                        <div class="pt2 w-100 w-100-l pr2-l">
                            <div class="pv2 b">
                                <label for="Date">Date</label>
                            </div>
                            <input type="date"
                                id="date"
                                class="product-input w-100"
                                v-model="state.date"
                            />
                        </div>
                    </div>
                    <hr />
                    <div class="flex flex-wrap justify-end" style="gap: 1rem">
                        <button class="mt1" @click.prevent="closeInventoryModal">Cancel</button>
                        <button class="mt1" type="submit" :disabled="state.newStockQty < 0 || (state.currentStockQty === state.newStockQty) || state.disableSubmitButton">
                            {{ state.disableSubmitButton ? 'Processing...' : 'Submit' }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { reactive, computed, ref, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import moment from 'moment-timezone'
import ShortUniqueId from 'short-unique-id'
import { formatAmount } from '@/utils/lib'
import CurrencyInput from '@/components/CurrencyInput'

export default {
    name: 'UpdateInventoryModal',
    components: { CurrencyInput },
    props: {
        closeModal: Function
    },

    data() {
        return {
            numberFormat: {
                currency: 'USD',
                currencyDisplay: 'hidden',
                hideCurrencySymbolOnFocus: true,
                hideGroupingSeparatorOnFocus: false,
                hideNegligibleDecimalDigitsOnFocus: true,
                autoDecimalDigits: false,
                useGrouping: true,
                accountingSign: true
            }
        }
    },

    setup(props, { emit }) {
        const modalRef = ref(null)
        const route = useRoute()
        const store = useStore()
        const showUpdateInventoryModal = computed(() => store.state?.Inventory?.showUpdateInventoryModal)
        const currentStockQty = computed(() => store.state?.Inventory?.currentStockQty)
        const selectedProduct = computed(() => store.state?.Inventory?.inventoryProduct)
        const bankAccounts = computed(() => store.state?.Settings?.bankAccounts)
        const accountDetails = computed(() => store?.state?.Transaction?.accountDetails)

        const initialState = {
            data: [],
            currentStockQty: 0,
            newStockQty: 0,
            variance: 0,
            type: '',
            account: '',
            amount: 0,
            date: moment().format('YYYY-MM-DD'),
            disableSubmitButton: false
        }
        const state = reactive(initialState)

        const closeInventoryModal = () => {
            props.closeModal()
            state.currentStockQty = 0
            state.newStockQty = 0
            state.variance = 0
            state.type = ''
            state.account = ''
            state.amount = 0
        }

        const prepareData = () => {
            const payload = {
                type: state.type,
                date: state.date,
                reference: new ShortUniqueId({ length: 6 }).randomUUID(),
                entity: '',
                entityName: '',
                orgCategory: 'PRODUCT',
                amount: Math.abs(state.amount),
            }

            if (state.type === 'sales' || state.type === 'purchases') {
                const price = (state.type === 'sales')
                    ? selectedProduct?.value?.sellingPrice
                    : selectedProduct?.value?.buyingPrice
                payload['items'] = [
                    {
                        name: selectedProduct?.value?.name,
                        product: selectedProduct?.value?._id,
                        price,
                        quantity: Math.abs(state.variance),
                        instock: selectedProduct?.value?.qty_in_stock,
                        productDropDown: false,
                        sku: selectedProduct?.value?.sku || ''
                    }
                ]
                payload['glAccounts'] = [{ glAccount: state.account, glAmount: state.amount }]
            } else if (state.type === 'stock_introduced') {
                payload.type = 'inflows'
                payload['subtype'] = 'stock_introduced'
                payload['description'] = selectedProduct?.value?.name
                payload['items'] = [
                    {
                        product: selectedProduct?.value?._id,
                        productName: selectedProduct?.value?.name,
                        quantity: Math.abs(state.variance),
                        sku: selectedProduct?.value?.sku || '',
                        unit_price: selectedProduct?.value?.buyingPrice,
                        amount: Math.abs(state.variance) * selectedProduct?.value?.buyingPrice
                    }
                ]
            } else if (state.type === 'missing_stock') {
                payload.type = 'outflows'
                payload['subtype'] = 'missing_stock'
                payload['description'] = selectedProduct?.value?.name
                payload['items'] = [
                    {
                        product: selectedProduct?.value?._id,
                        productName: selectedProduct?.value?.name,
                        quantity: Math.abs(state.variance),
                        sku: selectedProduct?.value?.sku || '',
                        unit_price: selectedProduct?.value?.buyingPrice,
                        amount: Math.abs(state.variance) * selectedProduct?.value?.buyingPrice
                    }
                ]
            } else if (state.type === 'damaged_stock') {
                payload.type = 'outflows'
                payload['subtype'] = 'damaged_stock'
                payload['description'] = selectedProduct?.value?.name
                payload['items'] = [
                    {
                        product: selectedProduct?.value?._id,
                        productName: selectedProduct?.value?.name,
                        quantity: Math.abs(state.variance),
                        sku: selectedProduct?.value?.sku || '',
                        unit_price: selectedProduct?.value?.buyingPrice,
                        amount: Math.abs(state.variance) * selectedProduct?.value?.buyingPrice
                    }
                ]
            } else if (state.type === 'promotion_stock') {
                payload.type = 'outflows'
                payload['subtype'] = 'promotion_stock'
                payload['description'] = selectedProduct?.value?.name
                payload['items'] = [
                    {
                        product: selectedProduct?.value?._id,
                        productName: selectedProduct?.value?.name,
                        quantity: Math.abs(state.variance),
                        sku: selectedProduct?.value?.sku || '',
                        unit_price: selectedProduct?.value?.buyingPrice,
                        amount: Math.abs(state.variance) * selectedProduct?.value?.buyingPrice
                    }
                ]
            } else if (state.type === 'stolen_stock') {
                payload.type = 'outflows'
                payload['subtype'] = 'stolen_stock'
                payload['description'] = selectedProduct?.value?.name
                payload['items'] = [
                    {
                        product: selectedProduct?.value?._id,
                        productName: selectedProduct?.value?.name,
                        quantity: Math.abs(state.variance),
                        sku: selectedProduct?.value?.sku || '',
                        unit_price: selectedProduct?.value?.buyingPrice,
                        amount: Math.abs(state.variance) * selectedProduct?.value?.buyingPrice
                    }
                ]
            }

            return payload;
        }

        const onSubmit = () => {
            state.disableSubmitButton = true
            const payload = prepareData();
            store.dispatch('Inventory/stockUpdate', payload).then(data => {
                    if(data.success) {
                        closeInventoryModal()
                        const query = route.query.limit && route.query.page ? { limit: route.query.limit, page: route.query.page } : null
                        store.dispatch('Inventory/getAllProducts', query)
                    }
                    state.disableSubmitButton = false
                })
                .catch(error => {
                    state.disableSubmitButton = false
                    console.error(error)
                })
        }

        const handleOnClickOutsideModal = e => {
            if (modalRef?.value && !modalRef?.value.contains(e.target)) {
                closeInventoryModal()
            }
        }

        watch(currentStockQty, newValue => {
            if (newValue) {
                state.currentStockQty = currentStockQty.value
                state.variance = state.newStockQty - state.currentStockQty
                if (state.variance >= 0) {
                    state.type = 'stock_introduced'
                } else if (state.variance < 0) {
                    state.type = 'missing_stock'
                }

                const price = (state.type === 'sales')
                ? selectedProduct?.value?.sellingPrice
                : selectedProduct?.value?.buyingPrice
                    
                state.amount = Math.abs(parseFloat(state.variance || 0) * parseFloat(price || 0))
            }
        })

        watch(() => state.newStockQty, newValue => {
            state.variance = state.newStockQty - state.currentStockQty
            
            if (newValue && state.variance >= 0) {
                state.type = 'stock_introduced'
            } else if (newValue && state.variance < 0) {
                state.type = 'missing_stock'
            }

            const price = (state.type === 'sales')
                ? selectedProduct?.value?.sellingPrice
                : selectedProduct?.value?.buyingPrice
                    
            state.amount = Math.abs(parseFloat(state.variance || 0) * parseFloat(price || 0))
        })

        watch(() => state.type, newValue => {
            const price = (newValue === 'sales')
                ? selectedProduct?.value?.sellingPrice
                : selectedProduct?.value?.buyingPrice
                    
            state.amount = Math.abs(parseFloat(state.variance || 0) * parseFloat(price || 0))
        })

        watch(accountDetails, newValue => {
            if (newValue) {
                state.data = Object.values(newValue)?.filter(value => value.type !== null && parseInt(value.balance) !== 0)
            }
        })

        onMounted(() => { store.dispatch('Transaction/getAccountDetails') })

        return {
            state,
            bankAccounts,
            onSubmit,
            showUpdateInventoryModal,
            currentStockQty,
            selectedProduct,
            modalRef,
            handleOnClickOutsideModal,
            closeInventoryModal,
            formatAmount
        }
    }
}
</script>

<style>
.entity-form input[type='radio'] {
    background: unset;
    border: none;
    box-shadow: none;
    padding: unset;
    width: unset;
}
</style>
